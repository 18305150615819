import React, { useEffect, useState } from "react";
import { Linking, StyleSheet, TextInput, TouchableOpacity } from "react-native";
import { greengoApi } from "../api/greengoApi";
import Loader from "../components/Loader";
import NavHeader from "../components/NavHeader";
import Popup from "../components/Popup";
import { Text, View } from "../components/Themed";
import { LoaderProvider } from "./../../src/context/LoaderContext";
import { PopupProvider } from "./../../src/context/PopupContext";
import { TokenContext } from "./../../src/context/TokenContext";
import { useQuery } from "./../../src/hooks/useQuery";
import { PopupInfos } from "./../../src/interfaces/PopupInfos";

export default function PairingScreen({}: any) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [accountBalance, setAccountBalance] = useState("");
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popup, setPopup] = useState<PopupInfos>({
    showImage: false,
    title: "",
    context: "",
    text: "",
    buttonText: "",
  });

  const [shouldLeave, setShouldLeave] = useState(false);
  const [errorUrl, setErrorUrl] = useState("");

  const [shouldSendEmail, setShouldSendEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");

  const togglePopup = () => {
    setShowPopup(!showPopup);
    if (shouldLeave == true) {
      loadInBrowser("");
    }
    if (errorUrl !== "") {
      loadInBrowser(errorUrl);
    }
  };

  const action = () => {
    togglePopup();
    if (shouldSendEmail == true) {
      loadInMail(email, subject);
    }
  };

  const setSuccessPopup = (
    successTile: string,
    successDescription: string,
    buttonText: string
  ) => {
    setPopup({
      showImage: true,
      title: successTile,
      context: "",
      text: successDescription,
      buttonText: buttonText,
    });
  };

  const setErrorPopup = (
    errorTitle: string,
    errorContext: string,
    errorDescription: string,
    buttonText: string
  ) => {
    setPopup({
      showImage: false,
      title: errorTitle,
      context: errorContext,
      text: errorDescription,
      buttonText: buttonText,
    });
  };

  const [isLoading, setIsLoading] = useState(false);

  const [mobileAppUrl, cagnotteId, userId] = useQuery();

  const loadInBrowser = (queryParameters: string) => {
    if (queryParameters === "") {
      Linking.openURL(String(mobileAppUrl)).catch((err) =>
        console.error("Couldn't load page", err)
      );
    } else {
      Linking.openURL(String(mobileAppUrl) + queryParameters).catch((err) =>
        console.error("Couldn't load page", err)
      );
    }
  };

  const loadInMail = (email: string, title: string) => {
    let url = "mailto:" + email + "?subject=" + title;
    Linking.openURL(url);
  };

  const affiliate = async (token: string) => {
    try {
      setErrorUrl("");
      setShowError(false);
      setIsLoading(true);
      if (firstName !== "" && lastName != "" && accountBalance != "") {
        if (token != null && cagnotteId != null && userId != null) {
          let pairingResponse = await greengoApi.pair(token, {
            cagnotteId: Number(cagnotteId),
            userId: Number(userId),
            solde: parseFloat(accountBalance.replace(",", ".")),
            firstname: firstName,
            lastname: lastName,
          });

          console.log(pairingResponse);
          if (pairingResponse.success == true) {
            let transferResponse = await greengoApi.transferCredit(token, {
              userId: Number(userId),
              cagnotteId: Number(cagnotteId),
            });

            if (
              transferResponse.type === "ServicePayload" ||
              transferResponse.hasOwnProperty("recreditMessage") == true
            ) {
              setIsLoading(false);
              setShowError(false);
              setSuccessPopup(
                "Merci",
                transferResponse.recreditMessage,
                "Revenir sur l'application"
              );
              setShouldLeave(true);
              setShouldSendEmail(false);
              setShowPopup(true);
            } else {
              setIsLoading(false);
              setErrorPopup(
                "Désolé",
                "Nous avons affilié votre badge entreprise mais le transfert de votre cagnotte n'a pas pu aboutir",
                "Veuillez nous contacter pour résoudre ce problème",
                "Nous contacter"
              );
              setErrorUrl("");
              setShouldSendEmail(true);
              setEmail("support@laconsignegreengo.com");
              setSubject("Erreur trasnfert - " + userId);
              setShowPopup(true);
              setShowError(false);
            }
          } else {
            setIsLoading(false);
            if (
              pairingResponse.errorLabel === "user_not_found" ||
              pairingResponse.errorLabel === "several_accounts_match" ||
              pairingResponse.errorLabel === "user_already_paired"
            ) {
              setErrorPopup(
                "Désolé",
                "L’affiliation n’a pas pu aboutir...",
                "Pensez à vérifier votre connexion ainsi que la saisie de vos informations et le montant du solde de votre badge entreprise (inscrit sur votre reçu ou à demander en caisse)",
                "Ok"
              );
            } else if (
              pairingResponse.errorLabel === "account_not_compatible"
            ) {
              setErrorPopup(
                "Désolé",
                "L’affiliation n’a pas pu aboutir...",
                "Votre badge entreprise n'est pas en mesure de recevoir le transfert. Veuillez nous contacter",
                "Nous contacter"
              );
              setShouldSendEmail(true);
              setEmail("support@laconsignegreengo.com");
              setSubject("Erreur d'affiliation badge entreprise - " + userId);
              setErrorUrl("");
              //setErrorUrl("?page=contact&case=error_affiliate")
            } else {
              setErrorPopup(
                "Désolé",
                "L'opération n’a pas pu aboutir...",
                "Veuillez réessayer et nous contacter via le menu de votre app' La Consigne GreenGo si le problème persiste",
                "Ok"
              );
              setErrorUrl("");
              setShouldSendEmail(false);
            }
            setShowPopup(true);
            setShowError(false);
          }
        } else {
          setIsLoading(false);
          setErrorPopup(
            "Désolé",
            "L'opération n’a pas pu aboutir...",
            "Veuillez réessayer et nous contacter via le menu de votre app' La Consigne GreenGo si le problème persiste",
            "Ok"
          );
          //setErrorPopup('Désolé', "Un probleme est survenu.",  "Merci de relancer la connexion à partir de votre application mobile")
          setErrorUrl("");
          setShouldSendEmail(false);
          setShowPopup(true);
          setShowError(false);
        }
      } else {
        setIsLoading(false);
        setError("Merci de remplir tous les champs");
        setShowError(true);
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      setErrorPopup(
        "Désolé",
        "Un probleme est survenu.",
        "Veuillez verifier votre connexion internet et nous contacter si le probleme persiste",
        "Ok"
      );
      setErrorUrl("");
      setShouldSendEmail(false);
      setShowPopup(true);
      setShowError(false);
    }
  };

  const renderError = () => {
    if (showError) {
      return (
        <View style={styles.textView}>
          <Text style={styles.text}>{error}</Text>
        </View>
      );
    }
  };

  useEffect(() => {
    document.title = "GreenGo";
  });

  return (
    <View style={styles.container}>
      <PopupProvider
        value={{
          showPopup: showPopup,
          togglePopup: togglePopup,
          action: action,
        }}
      >
        <Popup popupInfos={popup}></Popup>
      </PopupProvider>
      <LoaderProvider value={isLoading}>
        <Loader></Loader>
      </LoaderProvider>
      <View style={{ flex: 1, flexDirection: "column", width: "100%" }}>
        <View
          style={{
            width: "100%",
            height: "80%",
            backgroundColor: "#D4FFEB",
            flex: 0.8,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <NavHeader
            title={"AFFILIER MA CAGNOTTE\nA MON BADGE ENTREPRISE"}
            isLight={false}
          ></NavHeader>

          <View
            style={{
              flex: 1,
              flexDirection: "column",
              width: "100%",
              backgroundColor: "#D4FFEB",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={styles.textInputView}>
              <TextInput
                multiline={false}
                style={styles.textInput}
                placeholder="Prénom"
                placeholderTextColor="#6796FF"
                onChangeText={(text) => setFirstName(text)}
                value={firstName}
                onFocus={() => setShowError(false)}
              ></TextInput>
            </View>
            <View style={styles.textInputView}>
              <TextInput
                multiline={false}
                style={styles.textInput}
                placeholder="Nom"
                placeholderTextColor="#6796FF"
                onChangeText={(text) => setLastName(text)}
                value={lastName}
                onFocus={() => setShowError(false)}
              ></TextInput>
            </View>
            <View style={styles.textInputView}>
              <TextInput
                multiline={false}
                style={styles.textInput}
                placeholder="Dernier solde (en €)"
                placeholderTextColor="#6796FF"
                onChangeText={(text) => setAccountBalance(text)}
                value={accountBalance}
                onFocus={() => setShowError(false)}
              ></TextInput>
            </View>
            <View style={styles.textView}>
              <Text style={styles.textItalic}>
                Inscrit sur votre reçu{"\n"}ou à demander en caisse
              </Text>
            </View>
            {renderError()}
          </View>
        </View>
        <View
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#004FFE",
            flex: 0.2,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TokenContext.Consumer>
            {(context) => (
              <TouchableOpacity
                onPress={() => {
                  affiliate(context);
                }}
              >
                <View style={styles.containerButton}>
                  <View style={styles.button}>
                    <Text style={styles.textButton}>
                      Affilier ma cagnotte{"\n"}à mon badge entreprise
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            )}
          </TokenContext.Consumer>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#D4FFEB",
    minHeight: "100vh",
  },

  containerButton: {
    backgroundColor: "#004FFE",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    backgroundColor: "#fff",
    width: 280,
    height: 62,
    borderColor: "#FF9CFF",
    borderWidth: 4,
    borderRadius: 31,
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
  },
  textButton: {
    fontSize: 16,
    color: "#004FFE",
    fontWeight: "bold",
    textAlign: "center",
  },
  textInputView: {
    backgroundColor: "#fff",
    width: "80%",
    height: 62,
    borderColor: "#8BFEF6",
    borderWidth: 4,
    borderRadius: 31,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
    marginBottom: 15,
  },
  textInput: {
    fontSize: 16,
    color: "#004FFE",
    fontWeight: "bold",
    textAlign: "center",
    outlineColor: "transparent",
    outlineWidth: 0,
    outlineStyle: "none",
    boxShadow: "none",
    appearance: "none",
  },
  textView: {
    backgroundColor: "transparent",
    width: "80%",
    height: 62,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: 14,
    color: "#004FFE",
    fontWeight: "bold",
    textAlign: "center",
  },
  textItalic: {
    fontSize: 14,
    color: "#004FFE",
    fontWeight: "normal",
    fontStyle: "italic",
    textAlign: "center",
  },
});
