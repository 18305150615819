import { useEffect, useState } from "react";

export const useQuery = () => {
  const [mobileAppUrl, setMobileAppUrl] = useState<string | null>(null);
  const [cagnotteId, setCagnotteId] = useState<number | null>(null);
  const [userId, setUserId] = useState<number | null>(null);

  useEffect(() => {
    try {
      if (
        window.location.search !== null &&
        window.location.search !== undefined &&
        window.location.search !== ""
      ) {
        const hash2Obj: any = window.location.search
          .replace("?", "")
          .split("&")
          .map((v) => v.split("="))
          .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {});

        setMobileAppUrl(hash2Obj["app"] ?? null);
        setCagnotteId(hash2Obj["cagnotteId"] ?? null);
        setUserId(hash2Obj["userId"] ?? null);
      }
    } catch (e) {
      console.exception(e);
    }
  });

  return [mobileAppUrl, cagnotteId, userId];
};
