import active from "./active.env";

interface Env {
  GREENGO_API: string;
}

const envs: Record<string, Env> = {
  local: {
    GREENGO_API: "http://localhost:3000",
  },
  dev: {
    GREENGO_API: "https://api-dev.mygreengo.fr",
  },
  staging: {
    GREENGO_API: "https://api-staging.mygreengo.fr",
  },
  prod: {
    GREENGO_API: "https://api.mygreengo.fr",
  },
};

export default envs[active];
