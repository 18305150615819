import env from "../environment/env";
import { apiFetch, checkStatus } from "./utils";
const greengoUrl = env.GREENGO_API;

interface JESPairRequest {
  cagnotteId: number;
  userId: number;
  solde: number;
  firstname: string;
  lastname: string;
}

export interface SuccessPairResult {
  type: "SuccessPairResult";
  success: true;
}

export interface ErrorPairResult {
  type: "ErrorPairResult";
  success: false;
  errorLabel: PairError;
  errorDescription: PairError;
  errorCode: number;
}

export interface TransferCreditRequest {
  userId: number;
  cagnotteId: number;
}

export interface ServicePayload {
  type: "ServicePayload";
  paired: boolean;
  creditExecuted: boolean;
  recreditMessage: string;
  service: string;
  pairingMessage?: string;
  pairingUrl?: string;
}

export interface ServiceErrorPayload {
  type: "ServiceErrorPayload";
  service: string;
  creditError: string;
}

export type PairError = "user_not_found" | "several_accounts_match";

export interface GreengoApi {
  pair: (
    token: string,
    params: JESPairRequest
  ) => Promise<SuccessPairResult | ErrorPairResult>;
  transferCredit: (
    token: string,
    params: TransferCreditRequest
  ) => Promise<ServicePayload | ServiceErrorPayload>;
}

export const greengoApi: GreengoApi = {
  pair: async (token, params) => {
    try {
      const res = await apiFetch(`${greengoUrl}/interrop/pair/JES`, {
        method: "POST",
        body: JSON.stringify({ ...params }),
        headers: { "x-auth": token }
      });
      //checkStatus(res);
      let json = await res.json();
      return json
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  transferCredit: async (token, params) => {
    try {

      const res = await apiFetch(`${greengoUrl}/interrop/transferCredit`, {
        method: "POST",
        body: JSON.stringify({ ...params, service: "JES" }),
        headers: { "x-auth": token },
      });
      //checkStatus(res);
      return await res.json();
    } catch (e) {
      console.error(e);
      return null;
    }
  },
};
