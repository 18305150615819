import { StatusBar } from "expo-status-bar";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useQuery } from "./src/hooks/useQuery";
import { useToken } from "./src/hooks/useToken";

import PairingScreen from "./src/screens/PairingScreen";

import { TokenProvider, TokenConsumer } from "./src/context/TokenContext"

export default function App() {
  const token = useToken();
  const [mobileAppUrl, cagnotteId, userId] = useQuery();

  return (
    <>
      <TokenProvider value={String(token)}>
        <PairingScreen>

        </PairingScreen>
      </TokenProvider>
    </>

  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
