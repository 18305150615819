import { useState } from "react";

export const useToken = () => {
  const [token, setToken] = useState<string | null>(null);

  try {
    if (
      window.location.hash !== null &&
      window.location.hash !== undefined &&
      window.location.hash !== ""
    ) {
      const hash2Obj: any = window.location.hash
        .replace("#", "")
        .split("&")
        .map((v) => v.split("="))
        .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {});

      window.location.hash = ""; //we remove this value to avoid XXS attacks if the user go to an external link

      setToken(hash2Obj["segredo"]);
    }

    return token;
  } catch (e) {
    console.exception(e);
    return token;
  }
};
