import React, { useState, useContext } from "react";
import { StyleSheet, Image, TouchableOpacity } from "react-native";
import { Text, View } from "./Themed";

import { PopupContext } from "./../context/PopupContext";

import { PopupInfos } from "./../../src/interfaces/PopupInfos"

export default function Popup<PopupInfos>(popupInfos: PopupInfos) {

  //const [showPopup, setShowPopup] = useState(false)

  const handleImage = () => {
    if (popupInfos.popupInfos.showImage == true) {
      return <Image style={styles.mainImage} source={require('./../../assets/images/heart.png')}></Image>
    }
    else
      return <></>
  }

  return (
    <PopupContext.Consumer>
      {(context) => {

        if (context.showPopup) {
          return (
            <View style={styles.container}>
              <TouchableOpacity style={styles.popUpFade} onPress={() => {
                context.togglePopup()
              }}>

              </TouchableOpacity>

              <View style={styles.popUpContainer}>
                <TouchableOpacity style={styles.closeImageContainer} onPress={() => {
                  context.togglePopup()
                }}>

                  <Image style={styles.closeImage} source={require('./../../assets/images/close.png')}></Image>

                </TouchableOpacity>
                {
                  handleImage()

                }

                <Text style={styles.textTitle}>{popupInfos.popupInfos.title}</Text>
                <Text style={styles.textBold}>{popupInfos.popupInfos.context}</Text>
                <Text style={styles.text}>{popupInfos.popupInfos.text}</Text>
                <TouchableOpacity onPress={() => {
                  context.action()
                }}>
                  <View style={styles.button}>
                    <Text style={styles.textButton}>{popupInfos.popupInfos.buttonText}</Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          );
        }
        else {
          return (<View></View>)
        }
      }
      }
    </PopupContext.Consumer>
  )


}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    width: '100vw',
    height: '100%',
    zIndex: 20,
    backgroundColor: "transparent",
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
  },
  popUpFade: {
    position: 'absolute',
    top: 0,
    width: '100vw',
    height: '100%',
    backgroundColor: "rgba(212, 255, 235, 0.7)",
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
  },
  popUpContainer: {
    width: '90vw',
    borderRadius: 10,
    borderWidth: 5,
    borderColor: "#FFFFFF",
    backgroundColor: "#D4FFEB",
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
  },
  closeImageContainer: {
    position: 'absolute',
    height: 45,
    width: 45,
    top: 0,
    right: 0
  },
  closeImage: {
    height: 45,
    width: 45
  },
  mainImage: {
    height: 80,
    width: 93,
    marginTop: 15,
    marginBottom: 15
  },
  button: {
    backgroundColor: "#fff",
    width: 240,
    height: 42,
    borderColor: "#FF9CFF",
    borderWidth: 4,
    borderRadius: 31,
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 25,
    marginBottom: 15
  },
  textButton: {
    fontSize: 16,
    color: "#004FFE",
    fontWeight: "bold",
    textAlign: "center",

  },
  textTitle: {
    fontSize: 20,
    color: "#004FFE",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 15,
    marginBottom: 15
  },
  textBold: {
    fontSize: 14,
    color: "#004FFE",
    fontWeight: "bold",
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10
  },
  text: {
    fontSize: 14,
    color: "#004FFE",
    fontWeight: "normal",
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10
  }
});