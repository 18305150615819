import React, { useState } from "react";
import { StyleSheet, Image, ActivityIndicator } from "react-native";
import { Text, View } from "./Themed";

import { LoaderContext } from "./../context/LoaderContext";

export default function Popup({ }: any) {

  return (
    <LoaderContext.Consumer>
      {(isLoading) => {
        if (isLoading) {
          return (
            <View style={styles.container}>
              <View style={styles.loaderFade}>
              </View>
              <ActivityIndicator size="large" color="#004FFE" />
            </View>
          );
        }
        else {
          return (<View></View>)
        }
      }
      }
    </LoaderContext.Consumer>
  )

}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    width: '100vw',
    height: '100%',
    zIndex: 20,
    backgroundColor: "transparent",
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
  },
  loaderFade: {
    position: 'absolute',
    top: 0,
    width: '100vw',
    height: '100%',
    backgroundColor: "rgba(212, 255, 235, 0.7)",
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
  }
});