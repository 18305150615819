import React from "react";
import { StyleSheet } from "react-native";
import { Text, View } from "./Themed";

export default function NavHeader({
  navigation, title, isLight
}: any) {

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={isLight ? styles.titleLight : styles.titleDark}>
          {title}
        </Text>
        
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    position: 'absolute',
    top: 0,
    width: '100vw',
    height: 40,
    zIndex: 15,
    backgroundColor: "transparent"
  },
  header: {
    height: 40,
    width: "80%",
    backgroundColor: "transparent",
    alignItems: "center",
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center'
  },
  titleLight: {
    fontSize: 14,
    marginTop: 14,
    color: '#FFFFFF'
  },
  titleDark: {
    fontSize: 14,
    marginTop: 14,
    color: '#000000'
  },
});