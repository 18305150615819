import { createContext } from "react";

export interface PopupContextInterface {
    showPopup: boolean,
    togglePopup: any,
    action: any
}

export const PopupContext = createContext<PopupContextInterface>(
    {
        showPopup: false,
        togglePopup: undefined,
        action: undefined
    });

export const PopupProvider = PopupContext.Provider;
export const PopupConsumer = PopupContext.Consumer;
